#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgb(255, 255, 255);
  background-image: url(../../Assets/pre.svg);
  background-size: 3%;
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}
