.r-title {
  font-size: 3.5rem;
  color: #89cff0;
  font-weight: 610;
}

.r-description {
  margin-bottom: 1rem;
}

.r-a {
  text-decoration: none;
  color: #212529;
}

.research-item {
  margin: 2rem 0.5rem !important;
  transition: transform 0.3s ease; /* Smooth transition effect */
  border: 1.2px solid #ccc; /* Grey border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 1rem; /* Rounded corners */
  backdrop-filter: blur(1rem);
  padding: 1rem;
}

.research-item:hover {
  transform: scale(1.02);
}

.r-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rimg {
  width: 95%;
}

.r-right {
  position: relative;
}

.r-link {
  position: absolute;
  top: 0;
  right: 0;
  .none {
    display: none;
  }
}

.r-small-title {
  font-size: 1.4rem;
  font-weight: 410;
  margin-top: 2rem;
}

.r-description,
.r-ao,
.hstack,
.r-time {
  margin-top: 1rem;
}

.hstack {
  flex-wrap: wrap;
}
