.a-container {
  display: flex;
  flex-wrap: wrap;
}

.a-img {
  margin: 7px;
  text-align: center;
  flex: 1 1 188px; /* Flex grow, shrink, and basis */
}

img {
  width: 100%;
}
