.sticky {
  /* background-color: #1b1a2ea9 !important; */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: all 0.3s ease-out 0s !important;
  /* box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important; */
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 1rem !important;
    font-size: 1.4rem !important;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.navbar-nav .nav-link {
  color: #89cff0;
  font-weight: 600;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

/* Nav button */
.navbar-toggler {
  position: relative;
  background-color: transparent;
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-toggler span {
  display: block;
  background-color: #89cff0;
  height: 4px;
  width: 27px;
  margin-top: 5px;
  margin-bottom: 5px;
  transform: rotate(0deg);
  left: 0;
  /* opacity: 1; */
}
